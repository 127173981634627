import React from 'react';

import { Button } from '../../components';
import alien_icon from './info_alien.png';
import css from './InfoButton.module.css';

const InfoButton = props => {
  const { className, onClick } = props;

  return (
    <Button type="button" className={`${css.infoButton} ${className}`} onClick={onClick}>
      <span className={css.helperButton}>?</span>
      {/*  <img src={alien_icon} className={css.imageIcon} alt="Alien" /> */}
    </Button>
  );
};

export default InfoButton;
