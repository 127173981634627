import React from 'react';
import { Modal } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { oogoLingoItems, tipItems } from '../../containers/OogoLingoPage/OogoLingoPageItems';
import alien_talk from './alien_talk.png';

import css from './InfoModal.module.css';
import { ONBOARDING_TYPE_SERVICE } from '../../config/configCustomListing';

const InfoModal = props => {
  const { isInfoModalOpen, onOpenInfoModal, infoSelected, className, onboardingType = '' } = props;

  const noInfoMessage = <FormattedMessage id={'InfoModal.noInformation'} />;

  const handleCloseInfoModal = () => {
    onOpenInfoModal(false);
  };

  const getInfoTip = selected => {
    const selectedTip = selected;
    const getCategoryInfo = tipItems.filter(value => value.id === selectedTip);
    if (!getCategoryInfo) return null;
    const getOogoLingoItem = oogoLingoItems.filter(value => value.id === selectedTip);
    /*    .map(item => item.definition); */
    const title =
      getCategoryInfo.length > 0
        ? getCategoryInfo[0].label
        : getCategoryInfo[0]?.label ?? getOogoLingoItem[0].label;
    const definitionDefault = getOogoLingoItem.length > 0 ? getOogoLingoItem[0].definition : null;
    const definitionForType = onboardingType
      ? onboardingType === ONBOARDING_TYPE_SERVICE
        ? getCategoryInfo[0].forMinder
        : getCategoryInfo[0].forParent
      : null;
    const definition = definitionDefault || definitionForType;
    const getCategoryTips = getCategoryInfo.map(items =>
      items.tips.map((tips, index) => (
        <li className={css.listItem} key={index}>
          {tips}
        </li>
      ))
    );

    const infoTip =
      getCategoryTips.length > 0 && getCategoryTips[0].length > 0
        ? getCategoryTips
        : typeof title === 'string' && !definition
        ? noInfoMessage
        : null;

    return (
      <ul className={css.listTips}>
        <h3> {title}</h3>
        <h4> {definition}</h4>
        <div className={css.listContainer}> {infoTip}</div>
      </ul>
    );
  };

  return (
    <div>
      {isInfoModalOpen ? (
        <Modal
          className={`${css.modalInfo} ${className}`}
          id="EditListingDescription.infoModal"
          isOpen={isInfoModalOpen}
          onClose={() => handleCloseInfoModal()}
          onManageDisableScrolling={() => {}}
        >
          <div className={css.modalContainer}>
            <div className={css.modalContent}> {getInfoTip(infoSelected)}</div>
            <div className={css.imageInfoContainer}>
              <img src={alien_talk} className={css.imageInfo} alt="Alien" />
            </div>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

export default InfoModal;
