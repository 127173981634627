export const oogoLingoItems = [
  {
    id: 'minder',
    label: 'Minder™',
    definition: 'All the amazing people all over the world who care for children.',
  },
  {
    id: 'inspirer',
    label: 'Inspirer',
    definition: 'All the amazing people all over the world who teach children.',
  },
  {
    id: 'childcare',
    label: 'Regular Minder™',
    definition:
      'An amazing person who looks after children on a regular basis or recurring schedule (e.g. Full-Time Nanny or Part-Time Minder™).',
  },
  {
    id: 'occasional_minder',
    label: 'Occasional Minder™',
    definition:
      'An amazing person who looks after children occasionally or on an ad-hoc basis (e.g. Babysitter).',
  },
  {
    id: 'user',
    label: 'User',
    definition:
      'People who use öogo to find each other. Users can be Parents, Minders™ and Inspirers, Employers or Accomodation Hosts and Hotels.',
  },
  {
    id: 'profile',
    label: 'Profile',
    definition:
      'Core details that sit behind all your Listings. This is where people can get to know you better.',
  },

  {
    id: 'listing',
    label: 'Listing',
    definition:
      'A Minder™ or Inspirer’s place to let the world know how amazing they are. Like the homepage on your own website.',
  },
];

export const tipItems = [
  {
    id: 'childcare_daycare',
    label: 'Childcare & Daycare',
    forMinder:
      'Whether you can work Full-Time or can offer Occasional Babysitting, this category of Minder™ is for you.',
    forParent: '',
    tips: [
      'Regular Minder™',
      'Occasional Minder™',
      'Back Up Minder™',
      'Overnight Minder™',
      'Holiday Cover',
      'Daycare',
    ],
  },

  {
    id: 'pregnancy_and_birth',
    label: 'Pregnancy, Birth & New Born',
    forMinder:
      'If you work with parents or babies in the very, very, very early days, this category is for you.',
    forParent: '',
    tips: ['Doula', 'Sleep Consultant', 'Back-to-Work Coach', 'Maternity Nurse'],
  },
  {
    id: 'tutor_activities',
    label: 'Tutor, Activities & Camps',
    forMinder:
      'If you work with kids to help them develop skills and hobbies this category is for you.',
    forParent: '',
    tips: [
      'Academic Tutoring',
      'Technical Tutoring',
      'Music Tutoring',
      'Chess',
      'Coding',
      'Photography',
      'Sport Camps',
      'Creative Camps',
      'Music Camps',
      '... & much more!',
    ],
  },

  {
    id: 'tutor',
    label: 'Tutor',
    tips: ['Coding', 'Maths', 'Dancing', 'Art', 'Photography', 'Graphic Design', 'Languages'],
  },

  {
    id: 'minding_mentoring',
    label: 'Minding & Mentoring',
    tips: [
      'Enhanced matching.',
      'Connecting Minders™ & Inspirers with Parents who are prepared to offer career mentorship.',
    ],
  },
  {
    id: 'experience_description',
    label: 'Experience - Tell us about yourself',
    tips: [
      'Experience … Here is where you get to write about yourself. Why you do the work that you do, where you come from, what’s important to you and what you hope to gain from working with children?',
      'The more you can share, the easier it is for families to contact you if they feel you are right for them.',
    ],
  },
  {
    id: 'experience_memory',
    label: 'Experience - Favorite Memory',
    tips: [
      'Story … You mightn’t think so, but families can tell so much more about you if you tell them your favourite memory of minding or inspiring children. There’s so much to read between the lines about what’s important to you, what sort of person you are and all this helps families contact you if they feel you are right for them.',
    ],
  },
  {
    id: 'pricing',
    label: 'Pricing',
    tips: [
      'You can list your pricing by the hour or by the day.',
      'You can change your prices whenever you like.',
      'We recommend adding a sentence or two about how you charge and whether there is room for negotiation in your text.',
    ],
  },
  {
    id: 'availability',
    label: 'Availability ',
    tips: [
      'It’s crucial to update your availability regularly.',
      'If you have more than one listing you need to update your availability per listing each time.',
    ],
  },
];
